import { Action0 } from '@/utils/Types'
import { appUtils } from 'lib/AppUtils'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'

export const useTick = (
  defaultCountDown: Array<string>,
  countDownUnits: Array<moment.unitOfTime.Base>,
  endDate?: string,
  msLimit?: number,
  onPassed?: Action0,
  onFinish?: Action0
) => {
  const [countDown, setCountDown] = useState(defaultCountDown)
  useEffect(() => {
    let tick
    if (appUtils.isEmpty(endDate)) {
      onReset(tick) // no data
    } else {
      const now = moment.utc().local()
      const end = moment.utc(endDate).local()
      // eslint-disable-next-line prefer-const
      let duration = moment.duration(end.diff(now))
      if (duration.asMilliseconds() <= 0) {
        onPassed?.()
        onReset(tick) // end date has passed
        return
      } else {
        onUpdate(duration)
        tick = setInterval(() => {
          duration.subtract(1, 'second')
          if (duration.asMilliseconds() < 1) {
            onFinish?.()
            onReset(tick) // countdown ended
          } else {
            onUpdate(duration)
          }
        }, 1000)
      }
    }
    return () => {
      onReset(tick) // component unmount
    }
  }, [endDate])
  const onReset = (tick?: any) => {
    clearInterval(tick)
    tick = null
    setCountDown(defaultCountDown)
  }
  const onUpdate = duration => {
    if (!msLimit || duration.asMilliseconds() < msLimit) {
      setCountDown(() =>
        countDownUnits.map((unit, i) => {
          const num = i > 0 ? duration.get(unit) : Math.floor(duration.as(unit))
          return (num > 9 ? '' : '0') + num.toString()
        })
      )
    }
  }
  const allZeros = useMemo<boolean>(() => countDown.every(unit => unit == '00'), [countDown])
  return { countDown, setCountDown, allZeros }
}
