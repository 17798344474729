import { Dispatch, FC, HTMLAttributes, SetStateAction, useState } from 'react'

export const useDuoSliders = (from: number, to: number) => {
  const fromCtrl = useState(from)
  const toCtrl = useState(to)
  return { fromCtrl, toCtrl }
}

const DuoSliders: FC<{
  fromCtrl: [number, Dispatch<SetStateAction<number>>]
  toCtrl: [number, Dispatch<SetStateAction<number>>]
  min?: number
  max?: number
  outerCls?: string
  innerProps?: HTMLAttributes<HTMLDivElement>
}> = ({ fromCtrl, toCtrl, min = 0, max = 100, outerCls, innerProps }) => {
  const [from, setFrom] = fromCtrl
  const [to, setTo] = toCtrl
  return (
    <div className={`flex w-full items-center ${outerCls || ''}`}>
      <span>{from}</span>
      <div {...innerProps} className='duo-sliders'>
        <input
          className='from-slider'
          type='range'
          value={from}
          onChange={evt => {
            const val = Number(evt.target.value)
            if (val > to) {
              setTo(val)
              setFrom(to)
            } else {
              setFrom(val)
            }
          }}
          min={min}
          max={max}
        />
        <input
          className='to-slider'
          type='range'
          value={to}
          min={min}
          max={max}
          onChange={evt => {
            const val = Number(evt.target.value)
            if (val < from) {
              setFrom(val)
              setTo(from)
            } else {
              setTo(val)
            }
          }}
          style={{
            zIndex: to <= 0 ? 2 : 0,
            backgroundColor: 'transparent',
            backgroundImage: `linear-gradient(to right, #AAA9A9 0%, #AAA9A9 ${((from - min) / (max - min)) * 100}%, #FFD567 ${
              ((from - min) / (max - min)) * 100
            }%, #FFB800 ${((to - min) / (max - min)) * 100}%, #AAA9A9 ${((to - min) / (max - min)) * 100}%, #AAA9A9 100%)`,
          }}
        />
      </div>
      <span>{to}</span>
    </div>
  )
}
export default DuoSliders
