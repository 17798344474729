import { HTMLAttributes, PropsWithChildren, useEffect, useState } from 'react';
import CaretIcon from '../CaretIcon';

export const InformationLayout = (props: PropsWithChildren<{ show?: boolean; cls?: string; title?: string; toggleProps?: HTMLAttributes<HTMLDivElement> }>) => {
  const [show, toggleShow] = useState<boolean>(false)

  useEffect(() => {
    if (props.show && !show) {
      toggleShow(true)
    }
  }, [props.show])

  return (
    <div className={`w-full transition-all duration-300 ${props.cls || ''}`}>
      <div
        {...props.toggleProps}
        className={`flex w-full cursor-pointer justify-between rounded-lg border-2 py-2 px-4 font-semibold shadow-lg shadow-stone-200 transition-colors duration-300 ${
          show ? 'border-amber-400 bg-amber-100/75' : 'border-stone-200 hover:bg-amber-100/75'
        }`}
        onClick={() => toggleShow(prev => !prev)}
      >
        {props.title}
        <button aria-label='Toggle display'>
          <CaretIcon contentOpen={show} />
        </button>
      </div>
      <div className={`relative ${show ? 'flex animate-fade-in-down flex-col text-sm text-black' : 'hidden'}`}>{props.children}</div>
    </div>
  )
}
export default InformationLayout
