import { Format } from '@/utils/Format';
import { Competition } from '@models/Index';
import { FC } from 'react';

export const CompetitionPrice: FC<{ competition: Competition; extraCls?: string; monospace?: string }> = ({ competition, extraCls, monospace }) => {
  const price = competition.sale_price || competition.price
  return (
    <div className={`flex flex-row items-center ${extraCls || ''}`}>
      <div className='flex items-baseline font-semibold text-cyan-v2'>
        <span className={`${monospace || 'text-2xl'}`}>{Format.getCurrencySymbol()}</span>
        <span className={`pl-0.5 ${monospace || 'text-2xl'}`}>{Format.getNoDecimal(price)}</span>
        <span className={`${monospace || 'text-base'}`}>{Format.getDecimal(price)}</span>
      </div>
      {competition.sale_price ? <span className='ml-2 text-sm font-semibold text-black line-through'>{Format.withCurrency(competition.price)}</span> : null}
    </div>
  )
}

export default CompetitionPrice
