import { Action1 } from '@/utils/Types'
import { useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'

const FB_APP = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID
interface VideoDrawPlayerProps {
  url?: string
  iframe?: string
  className?: string
}

export default function VideoDrawPlayer(props: VideoDrawPlayerProps) {
  const { url, iframe, className } = props
  return url ? (
    <iframe
      title={url}
      src={url + `&show_text=false&appId=${FB_APP}`}
      loading='lazy'
      scrolling='no'
      frameBorder={0}
      allowFullScreen={true}
      allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'
      className={`aspect-video w-full cursor-pointer ${className || ''}`}
    />
  ) : iframe ? (
    <div dangerouslySetInnerHTML={{ __html: iframe }} />
  ) : null
}

export const ModalVideoDrawPlayer = function ({
  url,
  iframe,
  className,
  isPlaying,
  toggleIsPlaying,
  toggleIsPlayerIFrame,
}: {
  isPlayerIFrame?: boolean
  isPlaying?: boolean
  toggleIsPlaying?: Action1<boolean>
  toggleIsPlayerIFrame?: Action1<boolean>
} & VideoDrawPlayerProps) {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const modifiedUrl = url ? new URL(decodeURIComponent(url)).searchParams.get('href') : ''

  useEffect(() => {
    toggleIsPlayerIFrame(!ReactPlayer.canPlay(modifiedUrl))
  }, [url])

  useEffect(() => {
    setIsVideoPlaying(isPlaying)
  }, [isPlaying])

  return ReactPlayer.canPlay(modifiedUrl) ? (
    <ReactPlayer
      className={`aspect-video w-full cursor-pointer ${className || ''}`}
      controls={true}
      pip={true}
      stopOnUnmount={false}
      url={modifiedUrl}
      playing={isVideoPlaying}
      width='100%'
      height='100%'
      config={{ facebook: { appId: `${FB_APP}`, attributes: { 'data-show-text': false } } }}
      onPlay={() => {
        toggleIsPlaying(true)
        setIsVideoPlaying(true)
      }}
    />
  ) : url ? (
    //returns videodrawplayer if the url cannot be played in ReactPlayer
    // isPlayerIFrame is set to true
    <VideoDrawPlayer url={url} iframe={iframe} />
  ) : null
}
