import { AppLayout } from '@/components/Layouts/AppLayout'
import { useLanguage } from '@/hooks/Contexts'
import Link from 'next/link'
import { FC, Fragment, PropsWithChildren } from 'react'
//  'login' | 'register' | 'forget-password' | 'reset-password
const AuthLayout: FC<PropsWithChildren<{ type: number }>> = ({ type, children }) => {
  const { auth: authStr } = useLanguage()
  return (
    <Fragment>
      <AppLayout innerCls='flex flex-col items-center'>
        <div className='text-center text-xl font-bold capitalize md:text-2xl'>
          {[authStr.login, authStr.register, authStr.find_account, authStr.reset_password, authStr.email_verification][type]}
        </div>
        {children}
        {type > 2 ? null : (
          <div className='hidden lg:mt-6 lg:flex lg:justify-center lg:text-sm'>
            <span className='text-black'>{type == 1 ? authStr.have_account : authStr.no_account}</span>
            <Link href={type == 1 ? '/login' : '/register'}>
              <button className='ml-1 text-cyan-v2 hover:text-amber-v1'>{type == 1 ? authStr.login_now : authStr.register_now}</button>
            </Link>
          </div>
        )}
      </AppLayout>
      {type > 2 ? null : (
        <div className='container-white flex flex-col items-center justify-center bg-white/100 lg:hidden'>
          <span className='font-semibold text-black'>{type == 1 ? authStr.have_account : authStr.no_account}</span>
          <Link href={type == 1 ? '/login' : '/register'}>
            <button className='btn-rd-outline btn-hover-amber btn-disabled-grey mt-4 h-10 w-full max-w-sm'>
              {type == 1 ? authStr.login_now : authStr.register_now}
            </button>
          </Link>
        </div>
      )}
    </Fragment>
  )
}

export default AuthLayout
