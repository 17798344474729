import { useLanguage, useLayout } from '@/hooks/Contexts'
import style from '@/styles/HowToPlay.module.css'
import { FC } from 'react'

export const HowToPlay: FC<{ extraCls?: string }> = ({ extraCls }) => {
  const { how_to_play: strings } = useLanguage()
  const { isDesktop } = useLayout()
  return (
    <div id='how-to-play' className={`${isDesktop ? 'fluid-gray' : 'contents'} ${extraCls || ''}`}>
      <div className={`grid w-full grid-cols-1 md:grid-cols-2 xl:grid-cols-4 ${isDesktop ? 'container-gray' : 'container-white'}`}>
        <div className='flex flex-col justify-center text-xl lg:text-2xl'>
          <span className='font-semibold text-cyan-v2'>{strings.title}</span>
          <span className='mt-2'>{strings.descr}</span>
        </div>

        {strings.steps.map((item, index) => (
          <div
            key={item}
            className={`group relative flex flex-col rounded-xl bg-cyan-v3 text-center text-white/100 md:odd:mr-2 md:even:ml-2 xl:odd:ml-4 xl:odd:mr-0 xl:even:ml-4 ${
              index == 0 ? 'mt-12 md:mt-8' : 'mt-12 xl:mt-8'
            }`}
          >
            <img
              key={item}
              src='/assets/how_to_play.svg'
              className={`absolute inset-0 h-full w-full overflow-hidden rounded-xl ${style['htp-pulse-' + (index + 1)]}`}
            />
            <span
              className={`absolute inset-x-0 -top-7 mx-auto h-14 w-14 rounded-full p-2 text-center text-4xl font-semibold ${
                style['step-pulse-' + (index + 1)]
              }`}
            >
              {index + 1}
            </span>
            <span className='relative px-4 pt-10 text-xl font-semibold lg:text-2xl'>{item}</span>
            <div className='relative px-4 pb-5 pt-2'>{strings.steps_descr[index]}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default HowToPlay
