import { useCarousel } from '@/hooks/Carousel'
import { useLanguage, useLayout } from '@/hooks/Contexts'
import { API_URL } from '@/utils/Consts'
import { BannerItem } from '@models/Index'
import { appUtils } from 'lib/AppUtils'
import Link from 'next/link'
import { FC, Fragment, useEffect, useState } from 'react'
import useSWRImmutable from 'swr/immutable'
import { CompetitionCountdown } from './Competition'

export const BannerSlider: FC = () => {
  const { breakpoint } = useLayout()
  const [shownBanners, setShownBanners] = useState<BannerItem[]>()
  const { isValidating, isLoading, data } = useSWRImmutable(API_URL.banners, appUtils.fetcher)
  useEffect(() => {
    if (breakpoint && !isValidating && !isLoading) {
      if (Array.isArray(data) && data.length > 0) {
        const filtered = data.filter(item =>
          item?.image_url?.toLowerCase?.()?.includes?.(breakpoint?.includes?.('xl') ? 'desktop' : breakpoint?.includes?.('xs') ? 'mobile' : 'tablet')
        )
        setShownBanners(!appUtils.isEmpty(filtered) ? filtered : data)
      } else {
        setShownBanners([])
      }
    }
  }, [breakpoint, isValidating, isLoading, data])
  return shownBanners?.length > 0 ? (
    <BannerSliderContent banners={shownBanners} />
  ) : (
    <div className='mt-1 w-screen bg-[#050601] md:mt-20'>
      <div className={`main-container h-[490px] ${isValidating || isLoading ? 'animate-pulse bg-[#050601] dark:bg-black' : ''}`}>
        {isValidating || isLoading ? // <ImgSkeleton className='h-12 w-12 text-neutral-500 dark:text-neutral-600' />
        null : (
          <img src='/assets/banner_home.webp' className='pointer-events-none h-auto w-full animate-fade-in-down object-cover mix-blend-lighten' />
        )}
      </div>
    </div>
  )
}

const BannerSliderContent: FC<{ banners: Array<BannerItem> }> = ({ banners }) => {
  const { isDesktop } = useLayout()
  const {
    currentIndex,
    next,
    previous,
    gotoIndex,
    setMouseEnter,
    handleMouseLeave,
    handleMouseMove,
    handleMouseUp,
    handleMouseDown,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  } = useCarousel(banners || [], 5000, isDesktop)
  return (
    <Fragment>
      <div className='relative mt-16 h-full w-full animate-fade-in-down overflow-hidden bg-black md:mt-20'>
        <div className='main-container flex flex-col !px-0 xl:!px-4'>
          {banners?.length > 0
            ? banners.map((item, index) => (
                <Link href={item.details} key={index} passHref>
                  <a
                    className={`h-[320px] w-full cursor-pointer select-none sm:h-[350px] xl:h-[323px] ${
                      currentIndex === index ? 'animate-fade-in-right' : 'hidden'
                    }`}
                  >
                    <img
                      onMouseEnter={() => setMouseEnter(true)}
                      onMouseLeave={handleMouseLeave}
                      onMouseDown={handleMouseDown}
                      onMouseUp={handleMouseUp}
                      onMouseMove={handleMouseMove}
                      onTouchStart={handleTouchStart}
                      onTouchMove={handleTouchMove}
                      onTouchEnd={handleTouchEnd}
                      src={item.image_url}
                      className='object-fit h-full w-full'
                      alt={item.image_url}
                      draggable={false}
                      onDragStart={e => {
                        //equivalent of draggable={false} in Firefox
                        e.preventDefault()
                      }}
                    />
                  </a>
                </Link>
              ))
            : null}
        </div>

        {banners?.length > 1 ? (
          <Fragment>
            <button aria-label='Previous slide' className='group absolute left-4 top-1/2 z-10 -mt-4 hidden md:left-8 xl:flex' onClick={previous}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6 text-white/100 group-hover:text-cyan-v1'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M7 16l-4-4m0 0l4-4m-4 4h18' />
              </svg>
            </button>
            <button aria-label='Next slide' className='group absolute right-4 top-1/2 z-10 -mt-4 hidden md:right-8 xl:flex' onClick={next}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6 text-white/100 group-hover:text-cyan-v1'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M17 8l4 4m0 0l-4 4m4-4H3' />
              </svg>
            </button>
            <div className='absolute bottom-0 w-full py-1 md:py-2'>
              <div className='flex justify-center px-4 xl:container xl:!px-8'>
                {banners.map((item, index) => (
                  <button
                    aria-label={`Go to slide ${index}`}
                    key={index}
                    onMouseEnter={() => setMouseEnter(true)}
                    onMouseLeave={() => setMouseEnter(false)}
                    onClick={() => {
                      if (currentIndex !== index) {
                        gotoIndex(index)
                      }
                    }}
                    className={`mr-1 h-1 w-6 border border-neutral-600 md:w-7 ${index === currentIndex ? 'bg-white/100' : 'bg-transparent'}`}
                  />
                ))}
              </div>
            </div>
          </Fragment>
        ) : null}
      </div>

      {banners?.[currentIndex]?.title ? <Timer banner={banners?.[currentIndex]} /> : null}
    </Fragment>
  )
}

const Timer: FC<{ banner: BannerItem }> = ({ banner }) => {
  const { competitions: compStr } = useLanguage()
  return (
    <div className={`fluid-gray animate-fade-in-down bg-[url('/assets/bg-black2.png')] bg-cover bg-no-repeat`}>
      <div className='container-gray my-1.5 grid grid-cols-2 items-center justify-items-center gap-y-4 text-white/100 md:justify-items-stretch'>
        <div className='col-span-full text-xl md:col-span-1 md:!leading-9 lg:text-3xl'>{banner.title}</div>
        <CompetitionCountdown endDate={banner.end_time} light extraCls='col-span-full md:col-span-1 md:row-span-2' />
        <a href={banner.details} className='btn-rd-amber btn-hover-amber btn-width col-span-full flex items-center justify-center py-2 md:col-span-1'>
          {compStr.details}
        </a>
      </div>
    </div>
  )
}

export default BannerSlider
