import { useCount } from '@/hooks/Count'
import { toPercent } from '@/utils/FloatNumber'
import { FC, useEffect, useState } from 'react'

export const ProgressBar: FC<{
  percentage: number // fractional
  extraCls?: string
  pctCls?: string
  revert?: boolean
}> = ({ percentage, extraCls, pctCls, revert }) => {
  const [current, setCurrent] = useState<number>(0) // integer
  useEffect(() => {
    // for animation
    setCurrent(toPercent(percentage <= 0 ? 0 : percentage >= 1 ? 1 : percentage))
  }, [percentage])
  const delayed = useCount(current > 0, current, 1000)
  return (
    <>
      <div className={`flex w-full items-center overflow-clip rounded-full bg-stone-600 ${extraCls || ''}`}>
        <div
          className={`rounded-full border-none bg-[linear-gradient(250deg,#FFD567_-27%,#FFB800_7%,#FFDB7D_66%)] outline-none ${
            current == 0 ? 'h-0' : 'h-full'
          }`}
          style={{
            width: `${current}%`,
            transition: 'width 1s ease 0.3s',
          }}
        />
      </div>
      <div className={`text-right text-xs font-semibold text-stone-500 ${pctCls || ''}`}>{revert ? Math.ceil(100 - current) : Math.ceil(delayed)}%</div>
    </>
  )
}
export default ProgressBar
