import { useLanguage, useStats } from '@/hooks/Contexts'
import { useCount } from '@/hooks/Count'
import { useInView } from '@/hooks/ViewPort'
import { formatBigNum } from '@/utils/FloatNumber'
import { svgs } from '@/utils/Images'
import { HomeStats } from '@models/Index'
import { FC, useEffect, useState } from 'react'

export const WebInfo: FC<{ extraCls?: string }> = ({ extraCls }) => {
  const { web_info: strings } = useLanguage()
  const { home: stats } = useStats()

  const inView = useInView('web-info')
  const getStatsNum = (s: string) => parseInt(homeStats?.[s]?.match?.(/\d+/g)?.[0] ?? 0)

  const [homeStats, setHomeStats] = useState<HomeStats>({
    winners: '6000+',
    prizes: '40M+',
    charity: '40+',
    follows: '260k+',
  })

  useEffect(() => {
    if (stats) setHomeStats(stats)
  }, [stats])

  const winners = useCount(inView, getStatsNum('winners'), 1500)
  const prizes = useCount(inView, getStatsNum('prizes'), 1500)
  const charity = useCount(inView, getStatsNum('charity'), 1500)
  const follows = useCount(inView, getStatsNum('follows'), 1500)

  return (
    <div id='web-info' className={`container-gray grid grid-cols-2 gap-x-4 gap-y-4 pb-4 pt-4 md:pt-6 lg:grid-cols-4 lg:pt-8 ${extraCls || ''}`}>
      {/* <div className='col-span-full mx-auto flex flex-col items-center gap-x-2 border-b-2 border-amber-v1 px-4 pb-4 text-center text-base xs:text-lg sm:text-2xl md:text-3xl lg:flex-row'>
        <span className='xs:font-medium'>UK & Northern Irelands</span>
        <span className='font-medium text-cyan-v2 xs:font-semibold'>#1 Online competition destination</span>
      </div> */}
      {[
        { svgE: svgs.trophy, title: `£${formatBigNum(prizes)}`, desc: strings.in_prizes, unit: '+' },
        { svgE: svgs.winners, title: formatBigNum(winners), desc: strings.winners, unit: '+' },
        { svgE: svgs.charities, title: formatBigNum(charity), desc: strings.charities_supported, unit: '+' },
        { svgE: svgs.follows, title: formatBigNum(follows * 1000), desc: strings.follows, unit: '+', isLast: true },
      ].map((item, index) => (
        <div
          key={index}
          className={`flex h-full items-center justify-center ${index == 0 ? 'lg:justify-start' : index == 3 ? 'lg:justify-end' : ''} ${
            inView ? (index <= 2 ? 'animate-fade-in-left' : 'animate-fade-in-right') : ''
          }`}
        >
          <div className='animate-webinfo box-content h-10 w-10 rounded-full bg-[#05B7AC14] p-3 md:h-12 md:w-12 md:p-4'>{item.svgE}</div>
          <div className='ml-2 flex w-[70px] flex-col sm:ml-4 md:w-[90px] lg:w-fit xl:flex-row xl:items-center'>
            <span className='text-xl font-semibold text-amber-v1'>
              {item.title}
              {item.unit && <span>{item.unit}</span>}
            </span>
            <span className='text-xs md:text-base md:leading-4 lg:whitespace-nowrap xl:ml-1'>{item.desc}</span>
          </div>
        </div>
      ))}
    </div>
  )
}

export default WebInfo
