import { useLanguage } from '@/hooks/Contexts'
import { formatWithGBP } from '@/utils/FloatNumber'
import { Format } from '@/utils/Format'
import { noImage } from '@/utils/Images'
import { Winner } from '@models/Index'
import { useRouter } from 'next/router'
import { FC, HTMLAttributes } from 'react'
import { ImgFallback } from './Image'

export interface WinnerItemProps extends HTMLAttributes<HTMLDivElement> {
  winner: Winner
  disabled?: boolean
  imgCls?: string
  bodyCls?: string
  titleCls?: boolean | string
  dateCls?: boolean | string
  priceCls?: boolean | string
}

export const WinnerItem: FC<WinnerItemProps> = ({ winner, disabled, className, imgCls, bodyCls, titleCls, dateCls, priceCls, children, ...rest }) => {
  const { winners: winnerStr } = useLanguage()
  const router = useRouter()
  return (
    <div
      {...rest}
      className={`group flex flex-col overflow-clip relative${disabled ? '' : ' cursor-default'} ${className || ''}`}
      onClick={() => {
        if (!disabled) router.push('/winners')
      }}
    >
      <ImgFallback
        className={`pointer-events-none${disabled ? '' : ' cursor-pointer'} ${imgCls || ''}`}
        alt={winner.title || winner.image}
        src={winner.image?.startsWith?.('http') ? winner.image : noImage.src}
      />
      {children}
      {titleCls || dateCls || priceCls ? (
        <div className={`${bodyCls || 'contents'}`}>
          {titleCls ? <div className={`font-semibold ${titleCls}`}>{winner.title}</div> : null}
          {dateCls ? <div className={`${dateCls}`}>{Format.dateMonthYearFormat(winner.created_at)}</div> : null}
          {priceCls ? <div className={`${priceCls}`}>{winnerStr.won_for + formatWithGBP(winner.price)}</div> : null}
        </div>
      ) : null}
    </div>
  )
}
