import { useState, useEffect } from 'react'

export function useInView(id: string) {
  const [inView, setInView] = useState(false)
  useEffect(() => {
    const checkIfInView = () => {
      const el = document.getElementById(id)
      if (!el) return
      const rect = el.getBoundingClientRect()
      const inViewCheck =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      setInView(inViewCheck)
      if (inViewCheck) {
        document.body.removeEventListener('touchmove', checkIfInView)
        window.removeEventListener('scroll', checkIfInView)
      }
    }
    // touchmove for mobile
    document.body.addEventListener('touchmove', checkIfInView)
    window.addEventListener('scroll', checkIfInView)

    return () => {
      document.body.addEventListener('touchmove', checkIfInView)
      window.removeEventListener('scroll', checkIfInView)
    }
  }, [id])
  return inView
}
