import { useLanguage } from '@/hooks/Contexts'
import { FC, Fragment, PropsWithChildren, useMemo } from 'react'
import NavigationTab from '../Navigation/NavigationTab'
import { AppLayout } from './AppLayout'
import { AppLayoutProps } from './props'

const PoliciesLayout: FC<PropsWithChildren<AppLayoutProps>> = ({ children, ...rest }) => {
  return (
    <Fragment>
      <AppLayout {...rest} />
      <div className='fluid-gray'>
        <PoliciesNav />
        <div className='container-gray mt-4 animate-fade-in-down'>{children}</div>
      </div>
    </Fragment>
  )
}

const optionKeys = ['cookie-policy', 'privacy-policy', 'terms-conditions', 'terms-of-use', 'acceptable-use-policy']
const PoliciesNav: FC = () => {
  const { navigation: navStr } = useLanguage()
  const options = useMemo(() => optionKeys.map(key => ({ href: `/${key}`, text: navStr[key.replaceAll('-', '_')] })), [navStr])
  return <NavigationTab tabs={options} extraCls='mx-2 xl:container sm:mx-4 md:mx-auto md:px-4' />
}

export default PoliciesLayout
