import { useAuth } from '@/hooks/Auth'
import { Fragment, useEffect, useState } from 'react'
import { LoadingView } from '../FormElement'

export default function CredentialLayout(props: { children: any }) {
  const { user } = useAuth({ middleware: 'auth' })

  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    if (user === undefined) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [user])
  return (
    <Fragment>
      <LoadingView show={isLoading} />
      {!isLoading && props.children}
    </Fragment>
  )
}
