import { useLayout } from '@/hooks/Contexts'
import { Competition } from '@models/Index'
import { FC, useMemo, useRef } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import SliderArrows from '../buttons/SliderArrows'
import CompetitionItem from './CompetitionItem'

export const CompetitionSlider: FC<{ items: Array<Competition>; extraCls?: string }> = ({ items, extraCls }) => {
  const { isDesktop } = useLayout()
  const sliderRef = useRef<Slider>(null)
  const slidesToShow = useMemo(() => Math.min(isDesktop ? 3 : 2, items?.length || 0), [isDesktop, items?.length])
  return (
    <>
      <Slider
        ref={sliderRef}
        slidesToShow={slidesToShow}
        slidesToScroll={1}
        arrows={false}
        infinite={true}
        initialSlide={0}
        centerMode={false}
        centerPadding='0px'
        className={`comp-slider overflow-hidden md:px-4 ${extraCls || ''}`}
      >
        {items.map((item: Competition) => (
          <CompetitionItem key={item.id} competition={item} extraCls='h-full' />
        ))}
      </Slider>
      <SliderArrows
        prevClick={() => sliderRef.current?.slickPrev?.()}
        nextClick={() => sliderRef.current?.slickNext?.()}
        className='justify-center'
        btnCls='shadow-lg shadow-stone-200 last:ml-8'
      />
    </>
  )
}
export default CompetitionSlider
