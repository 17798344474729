import { useLanguage } from '@/hooks/Contexts'
import { floatDivision, toIntString } from '@/utils/FloatNumber'
import { Format } from '@/utils/Format'
import { noImage, svgs } from '@/utils/Images'
import { Competition } from '@models/Index'
import Link from 'next/link'
import { FC, Fragment } from 'react'
import { ImgFallback } from '../Image'
import ProgressBar from '../ProgressBar'
import CompetitionPrice from './CompetitionPrice'
import CompetitionTag from './CompetitionTag'

export const CompetitionItem: FC<{ competition: Competition; extraCls?: string; isFirst?: boolean }> = ({ competition, extraCls, isFirst }) => {
  const { competitions: compStr } = useLanguage()
  return (
    <div
      // onClick={() => router.push('/competitions/' + competition.short_name)}
      className={`bg-comp-item group relative -m-0.5 cursor-pointer rounded-lg p-0.5 ${extraCls || ''}`}
      // outer wrapper uses the gradient background image as the card's background and border color, which only shows when hovered
    >
      {/* inner wrapper styles the shadow when not hovered */}
      <div className='flex h-full flex-col overflow-hidden rounded-lg shadow-lg shadow-stone-200 transition-shadow duration-300 group-hover:shadow-none'>
        {competition.tickets_left == 0 && (
          <Fragment>
            <div className='sold-out-container' />
            <div className='absolute left-0 top-0 z-20 flex h-full w-full items-center justify-center'>
              <span className='sold-out'>{compStr.sold_out}</span>
            </div>
          </Fragment>
        )}

        {/* half-transparent colored overlay to cover the body except the image */}
        <div className='absolute inset-0 bg-amber-v1 opacity-0 transition-opacity duration-300 group-hover:opacity-5'></div>

        {/* image's css display must be relative, to render above the overlay */}
        <ImgFallback
          className='relative h-auto max-h-60 w-full rounded-t bg-white/100 object-cover'
          alt={competition.title || competition.banner_image}
          src={competition.banner_image || noImage.src}
        />

        {/* body's css display must not be relative, to render below the overlay */}
        <div className='flex h-full flex-1 flex-col bg-white/100 px-2 py-2 shadow-lg shadow-stone-200/75 sm:px-3'>
          <div className='flex flex-col items-start justify-start md:flex-row md:flex-wrap md:items-center md:gap-x-2'>
            <CompetitionPrice competition={competition} />
            {competition.tag ? <CompetitionTag tag={competition.tag} /> : null}
          </div>
          <div className='mt-1.5 break-words border-t border-stone-300 pt-2 text-sm font-semibold'>{competition.title}</div>
          <div className='mt-1 flex flex-row flex-wrap justify-between text-xs font-semibold text-neutral-600'>
            <span className='mr-2'>{Format.endTimeFormat(competition.draw_date)}</span>
            <div className='flex flex-row items-center'>
              <svg className='mr-1 h-3 w-3 text-cyan-v2' viewBox='0 0 9 9' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                {svgs.ticket}
              </svg>
              <span>{competition.tickets_total}</span>
            </div>
          </div>
        </div>

        {/* footer background must be transparent, to show the card's gradient background */}
        <div className='flex items-center gap-x-2 whitespace-nowrap bg-stone-50 bg-transparent px-2 pt-2 text-xs transition-all sm:px-3'>
          {isFirst ? <span className='hidden md:inline'>{compStr.ticket_sold}</span> : null}
          <ProgressBar percentage={floatDivision(competition.tickets_sold, competition.tickets_total)} extraCls='grow-1 h-2.5' />
        </div>
        <div className='flex items-center justify-center whitespace-nowrap bg-stone-50 bg-transparent px-2 pb-2 pt-0.5 text-xs text-stone-500 transition-all sm:px-3'>
          {toIntString(competition.tickets_sold, true)}/{toIntString(competition.tickets_total, true)}
        </div>
      </div>

      {/* use overlay link instead of wrapping div with anchor */}
      <Link href={'/competitions/' + competition.short_name} passHref>
        <a className='absolute inset-0' />
      </Link>
    </div>
  )
}
export default CompetitionItem
