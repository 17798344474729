import { Format } from '@/utils/Format'
import { HTMLAttributes } from 'react'
export default function TotalLayout(props: {
  name: string
  value: number
  isDiscount?: boolean
  outerCls?: string
  innerProps?: HTMLAttributes<HTMLSpanElement>
}) {
  return (
    <div className={`flex justify-between text-sm ${props.outerCls || ''} ${props.isDiscount === true ? 'text-xs text-error' : ''}`}>
      <span>{props.name}</span>
      <span {...props.innerProps}>
        {props.isDiscount && '-'}
        {Format.withCurrency(props.value)}
      </span>
    </div>
  )
}
