import { useState, useEffect, MouseEvent, TouchEvent } from 'react'

export function useCarousel<T>(items: T[], intervalMS?: number, isDesktop?: boolean) {
  const [state, setState] = useState({
    currentIndex: 0,
    mouseEnter: false,
    mousePressed: false,
    touchStart: false,
    //startX is the X-coordinate in pixels where the mouse is clicked relative to the page
    startX: 0,
  })
  const { currentIndex, mouseEnter, mousePressed, startX, touchStart } = state
  const next = () => {
    if (mouseEnter || touchStart) return
    setState(s => ({
      ...s,
      currentIndex: getNextIndex(),
    }))
  }

  const previous = () => {
    if (mouseEnter || touchStart) return
    setState(s => ({
      ...s,
      currentIndex: getPrevIndex(),
    }))
  }

  const gotoIndex = (index: number) => {
    setState(s => ({ ...s, currentIndex: index, mousePressed: false, mouseEnter: false }))
  }
  const setMouseEnter = (mouseEnter: boolean) => setState(s => ({ ...s, mouseEnter }))
  const setMousePressed = (mousePressed: boolean) => setState(s => ({ ...s, mousePressed }))
  const setTouchStart = (touchStart: boolean) => setState(s => ({ ...s, touchStart }))
  const handleTouchStart = (e: TouchEvent<HTMLImageElement>) => {
    setTouchStart(true)
    //to prevent vertical scrolling
    document.body.style.overflow = 'hidden'
    //startX is the X-coordinate in pixels where the mouse is clicked relative to the page
    setState(s => ({ ...s, startX: e.nativeEvent?.touches[0]?.pageX ?? 0 }))
  }
  const handleTouchMove = (e: TouchEvent<HTMLImageElement>) => {
    if (!touchStart) return
    drag(e, e.nativeEvent?.touches[0]?.pageX)
  }
  const handleTouchEnd = (e: TouchEvent<HTMLImageElement>) => {
    setTouchStart(false)
    document.body.style.overflow = 'visible'
    changeTransform(e.target, 0)
  }
  const handleMouseMove = (e: MouseEvent<HTMLImageElement>) => {
    if (!mousePressed || !mouseEnter) return
    drag(e, e.nativeEvent.pageX)
  }

  const handleMouseLeave = (e: MouseEvent<HTMLImageElement>) => {
    setMouseEnter(false)
    setMousePressed(false)
    changeTransform(e.target, 0)
  }

  const handleMouseUp = (e: MouseEvent<HTMLImageElement>) => {
    setMousePressed(false)
    changeTransform(e.target, 0)
  }

  const handleMouseDown = (e: MouseEvent<HTMLImageElement>) => {
    setMousePressed(true)
    setState(s => ({ ...s, startX: e?.nativeEvent.pageX ?? 0 }))
  }

  const changeTransform = (eventTarget: EventTarget, offset: number) => {
    const target = eventTarget as HTMLImageElement
    target.style.cssText = `
      transform: translateX(${offset}px);
      transition: transform ease-in-out;
    `
  }
  const getNextIndex = () => {
    return currentIndex === items.length - 1 ? 0 : currentIndex + 1
  }

  const getPrevIndex = () => {
    return currentIndex === 0 ? items.length - 1 : currentIndex - 1
  }

  const drag = (e: TouchEvent<HTMLImageElement> | MouseEvent<HTMLImageElement>, offsetX: number) => {
    // e.preventDefault()
    //offsetX is the X-coordinate in pixels where the image has moved relative to the page
    const target = e.target as HTMLImageElement
    //dragX is the amount of pixels the image is dragged from startX
    const dragX: number = offsetX - startX
    //percent of image dragged that will trigger a change in index
    // example: when 50% or 0.5 of image is dragged to the left or right
    // it will transition to next/previous index
    const IMAGE_PERCENTAGE = isDesktop ? 0.5 : 0.3
    if (Math.abs(dragX) > target.offsetWidth * IMAGE_PERCENTAGE) {
      //if dragX is negative, it means go to left else go to right
      const nextIndex = dragX < 0 ? getNextIndex() : getPrevIndex()
      setTouchStart(false)
      setMousePressed(false)
      changeTransform(e.target, 0)
      gotoIndex(nextIndex)
    } else {
      changeTransform(e.target, dragX)
    }
  }

  useEffect(() => {
    const token = intervalMS
      ? setInterval(() => {
          next()
        }, intervalMS)
      : null

    return () => {
      if (token) clearInterval(token)
    }
  }, [next])

  return {
    ...state,
    next,
    previous,
    gotoIndex,
    setMouseEnter,
    handleMouseMove,
    handleMouseUp,
    handleMouseDown,
    handleMouseLeave,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  }
}
