import { useLanguage, useLayout } from '@/hooks/Contexts'
import { API_URL } from '@/utils/Consts'
import { Competition } from '@models/Index'
import { appUtils } from 'lib/AppUtils'
import Link from 'next/link'
import { FC, useEffect, useState } from 'react'
import CompetitionItem from './CompetitionItem'
import CompetitionSlider from './CompetitionSlider'

export const RecommendCompetitions: FC<{ ids?: number | number[]; extraCls?: string; titleCls?: string; bodyCls?: string }> = ({
  ids,
  extraCls,
  titleCls,
  bodyCls,
}) => {
  const {
    competitions: compStr,
    categories: { view_all },
  } = useLanguage()
  const { breakpoint } = useLayout()
  const [data, setData] = useState<Competition[]>([])
  useEffect(() => {
    appUtils.fetcher(`${API_URL.recommend}?store_id=1${ids ? '&competition_ids=' + ids : '&perPage=4'}`, false).then(res => setData(res))
  }, [ids])
  return (
    data?.length > 0 && (
      <div className={`${extraCls || 'contents'}`}>
        <div className={`mb-4 flex w-full items-center justify-between text-lg ${titleCls || ''}`}>
          <span className='text-base md:text-2xl'>{ids ? compStr.related_competitions : compStr.competitions_ending_soon}</span>
          <Link href='/competitions' passHref>
            <a className='clickable-text text-sm font-semibold md:text-base'>{view_all}</a>
          </Link>
        </div>
        {!breakpoint ? null : breakpoint.includes('xl') ? (
          <div className={`grid w-full animate-fade-in-down grid-cols-4 gap-4 ${bodyCls || ''}`}>
            {data.map((item, index) => (
              <CompetitionItem key={item.id} competition={item} isFirst={index == 0} />
            ))}
          </div>
        ) : (
          <CompetitionSlider items={data} extraCls={bodyCls} />
        )}
      </div>
    )
  )
}

export default RecommendCompetitions
