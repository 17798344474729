import IconButton from '@/components/buttons/IconButton'
import { CompetitionItem } from '@/components/Competition'
import { Dropdown, TextInput } from '@/components/FormElement'
import DuoSliders, { useDuoSliders } from '@/components/FormElement/DuoSliders'
import { ImgSkeleton } from '@/components/Image'
import { ModalContainer } from '@/components/Modal'
import { useLanguage, useLayout } from '@/hooks/Contexts'
import { API_URL } from '@/utils/Consts'
import { svgs } from '@/utils/Images'
import { Competition } from '@models/Index'
import { appUtils } from 'lib/AppUtils'
import moment from 'moment'
import { Dispatch, FC, Fragment, memo, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

export const AllCompetitions: FC<{ data: any; extraCls?: string }> = ({ data, extraCls }) => {
  const {
    errors: { no_data },
  } = useLanguage()
  const [cateList, setCateList] = useState<Array<{ text: string; value: any }>>([{ text: 'All Categories', value: null }])
  const [compList, setCompList] = useState<Array<Competition>>([])

  useEffect(() => {
    appUtils.fetcher(API_URL.categoryList, true).then(res => {
      if (res?.length > 0) {
        setCateList(() => {
          const newVal = [{ text: 'All Categories', value: null }]
          res.sort((a, b) => a['id'] - b['id']).forEach(item => newVal.push({ text: item.name, value: item.short_name }))
          return newVal
        })
      }
    })
  }, [])

  return (
    <Fragment>
      <Filter cateList={cateList} allComp={data} setCompList={setCompList} />
      <div className={`fluid-gray ${extraCls || ''}`}>
        <div className='container-gray grid animate-fade-in-down grid-cols-2 gap-2 sm:gap-4 md:grid-cols-3 xl:grid-cols-4' data-testid='div-competitionsList'>
          {!data ? (
            <Skeletons />
          ) : compList?.length > 0 ? (
            compList.map((comp, index) => <CompetitionItem key={comp.id} competition={comp} isFirst={index == 0} />)
          ) : (
            <div className='col-span-full row-span-full animate-fade-in-down place-self-center py-24 text-center'>{no_data}</div>
          )}
        </div>
      </div>
    </Fragment>
  )
}

const Skeletons: FC<{ numOfItems?: number }> = memo(function Skeleton({ numOfItems = 12 }) {
  const skeletons = []
  for (let index = 0; index < numOfItems; index++) {
    skeletons.push(
      <div key={index} className='animate-fade-in-down rounded-lg shadow-lg shadow-stone-200'>
        <div className='skeleton-wrap'>
          <div className='skeleton-img-wrap h-52 rounded-t-lg'>
            <ImgSkeleton className='skeleton-img' />
          </div>
          <div className='skeleton-line mx-2 mt-4 h-4 w-1/2 sm:px-3'></div>
          <div className='skeleton-line mx-2 mt-4 h-px sm:px-3'></div>
          <div className='skeleton-line mx-2 mt-4 h-3 sm:px-3'></div>
          <div className='skeleton-line mx-2 mb-4 mt-4 h-3 w-1/2 sm:px-3'></div>
          <div className='skeleton-line mx-2 mb-4 mt-auto h-3 sm:px-3'></div>
        </div>
      </div>
    )
  }
  return <Fragment>{skeletons}</Fragment>
})

const OPTIONS = [
  { key: 'featured', order: null, value: 'featured', text: 'Featured' },
  { key: 'price', order: 'desc', value: 'price_desc', text: 'Price: High to low' },
  { key: 'price', order: 'asc', value: 'price_asc', text: 'Price: Low to high' },
  { key: 'closing_date', order: 'asc', value: 'closing_date_desc', text: 'Next to End' }, // end_time
  { key: 'closing_date', order: 'desc', value: 'closing_date_asc', text: 'Longest to End' }, // end_time
  { key: 'title', order: 'desc', value: 'title_desc', text: 'A to Z' },
  { key: 'title', order: 'asc', value: 'title_asc', text: 'Z to A' },
]

const sortFn = (category: Record<string, any>, sortBy: Record<string, any>) => (a: Competition, b: Competition) => {
  const asc = sortBy?.order == 'asc'
  if (sortBy?.key == 'price') {
    const aPrice = Number(a.sale_price || a.price)
    const bPrice = Number(b.sale_price || b.price)
    return asc ? aPrice - bPrice : bPrice - aPrice
  } else if (sortBy?.key == 'title') {
    return asc ? b.title.localeCompare(a.title) : a.title.localeCompare(b.title)
  } else if (sortBy?.key?.includes('date')) {
    const aTime = moment.utc(a.time).valueOf()
    const bTime = moment.utc(b.time).valueOf()
    return asc ? aTime - bTime : bTime - aTime // end_time
  } else {
    const cate = category?.value ?? 'home'
    const aPosition = a.categories?.find?.(({ short_name }) => short_name == cate)?.position
    const bPosition = b.categories?.find?.(({ short_name }) => short_name == cate)?.position
    if (isNaN(aPosition) && isNaN(bPosition)) {
      const aTime = moment.utc(a.time).valueOf()
      const bTime = moment.utc(b.time).valueOf()
      return aTime - bTime == 0 ? b.id - a.id : aTime - bTime
    } else if (isNaN(bPosition)) {
      return -1 // a should come before b
    } else if (isNaN(aPosition)) {
      return 1 // a should come after b
    } else {
      return aPosition - bPosition
    }
  }
}

const Filter: FC<{
  cateList: Array<Record<string, any>>
  allComp: any
  setCompList: Dispatch<SetStateAction<Competition[]>>
}> = ({ cateList, allComp, setCompList }) => {
  const { actions: actStr, competitions: compStr } = useLanguage()
  const { isDesktop } = useLayout()

  const defaultFrom = useMemo(() => 0, [])
  const defaultTo = useMemo(
    () =>
      allComp?.reduce?.((acc: number, item: Competition) => {
        const price = Math.ceil(Number(item.sale_price || item.price) / 10) * 10
        return price > acc ? price : acc
      }, 20) || 100,
    [allComp]
  )

  const { fromCtrl, toCtrl } = useDuoSliders(defaultFrom, defaultTo)
  const [from, setFrom] = fromCtrl
  const [to, setTo] = toCtrl
  const [sortBy, setSortBy] = useState<Record<string, any>>(null)
  const [category, setCategory] = useState<Record<string, any>>({})
  const [search, setSearch] = useState<string>('')
  const [showSort, setShowSort] = useState<boolean>(false)
  const [showFilter, setShowFilter] = useState<any>(null)

  const onSort = useCallback((sCategory: Record<string, any>, sSortBy: Record<string, any>) => {
    setSortBy(sSortBy)
    setCompList(prev => {
      const newVal = [...prev]
      newVal.sort(sortFn(sCategory, sSortBy))
      return newVal
    })
  }, [])

  const applyFilters = useCallback(
    (sCategory: Record<string, any>, sFrom: number, sTo: number, sSortBy: Record<string, any>, sSearch: string) => {
      const newVal =
        allComp?.reduce?.((arr: Competition[], item: Competition) => {
          const price = Number(item.sale_price || item.price)
          if (
            (!sCategory?.value || item?.categories?.some?.(({ short_name }) => short_name == sCategory.value)) && // filter category
            (!sSearch || item.title.toLowerCase().includes(sSearch.toLocaleLowerCase())) && // filter search title
            ((sFrom == defaultFrom && sTo == defaultTo) || (price >= sFrom && price <= sTo)) // filter min max price
          ) {
            return arr.concat(item)
          } else {
            return arr
          }
        }, []) || []
      newVal.sort(sortFn(sCategory, sSortBy))
      setCompList(newVal)
    },
    [allComp]
  )
  const clearFilters = useCallback(() => {
    setFrom(defaultFrom)
    setTo(defaultTo)
    setSortBy(null)
    setCategory({})
  }, [defaultFrom, defaultTo])

  const clearAndApply = useCallback(() => {
    clearFilters()
    applyFilters({}, defaultFrom, defaultTo, null, '')
  }, [defaultFrom, defaultTo])

  // init competitions list
  useEffect(() => {
    clearAndApply()
  }, [allComp])

  useEffect(() => {
    if (isDesktop) {
      applyFilters(category, from, to, sortBy, search)
    }
  }, [from, to])

  return (
    <div className={`sticky top-16 z-[1] w-screen bg-stone-600 bg-[url('/assets/bg-gray.png')] bg-cover bg-no-repeat py-3 text-sm md:top-20 md:py-5`}>
      <div className='container-gray flex items-center'>
        {isDesktop ? (
          <>
            <div className='mr-auto flex w-full max-w-[224px] flex-col items-center text-white/100 lg:max-w-[calc(25%-12px)]'>
              {compStr.ticket_prices}
              <DuoSliders
                fromCtrl={fromCtrl}
                toCtrl={toCtrl}
                min={defaultFrom}
                max={defaultTo}
                outerCls='gap-x-1'
                // @ts-ignore
                innerProps={{ 'data-testid': 'slider-filter-ticketPrice' }}
              />
            </div>
            <Dropdown
              outerCls='ml-2 lg:ml-4'
              innerCls='!shadow-none !m-0 !py-1 !px-2 lg:!px-4'
              menuCls='!max-h-max'
              optionCls='!py-1 !px-2 lg:!px-4'
              input={{
                'value': sortBy?.text || '',
                'placeholder': compStr.sort_by,
                'className': 'cursor-pointer w-36',
                // @ts-ignore
                'data-testid': 'div-filter-order',
              }}
              options={OPTIONS}
              value={sortBy?.value || 'featured'}
              onSelect={option => onSort(category, option)}
              overlay
            />
            <Dropdown
              outerCls='ml-2 lg:ml-4'
              innerCls='!shadow-none !m-0 !py-1 !px-2 lg:!px-4'
              menuCls='!max-h-max'
              optionCls='!py-1 !px-2 lg:!px-4'
              input={{
                'value': category.text || '',
                'placeholder': compStr.category,
                'className': 'cursor-pointer w-[120px]',
                // @ts-ignore
                'data-testid': 'div-filter-category',
              }}
              value={category.value}
              options={cateList}
              onSelect={option => {
                setCategory(option)
                applyFilters(option, from, to, sortBy, search)
              }}
              overlay
            />
            <IconButton onClick={clearAndApply} className='ml-2 flex h-7 w-7 p-1 lg:ml-4' data-testid='btn-competitions-refresh'>
              {svgs.reload}
            </IconButton>
          </>
        ) : null}

        <TextInput
          prepend={<span className='mr-1 w-5 text-neutral-600 lg:mr-2'>{svgs.search}</span>}
          input={{
            'value': search,
            'placeholder': compStr.search_prize,
            'onChange': evt => {
              setSearch(evt.target.value)
              applyFilters(category, from, to, sortBy, evt.target.value)
            },
            'className': 'w-full !text-sm',
            // @ts-ignore
            'data-testid': 'inp-competitions-searchPrize',
          }}
          // clear search input
          append={
            search ? (
              <span
                onClick={() => {
                  setSearch('')
                  applyFilters(category, from, to, sortBy, '')
                }}
                className='ml-1 box-content w-2 cursor-pointer rounded-full bg-neutral-600 p-1 text-white/100 lg:ml-2'
              >
                {svgs.cross}
              </span>
            ) : null
          }
          outerCls='md:ml-2 lg:ml-4 w-full md:w-[calc(25%-12px)]'
          innerCls='!shadow-none !m-0 !py-1 !px-2'
          noBorder
        />

        {isDesktop ? null : (
          <>
            <IconButton className='ml-4 flex h-7 w-7 p-1' onClick={() => setShowSort(true)}>
              {svgs.sort_down}
            </IconButton>
            <ModalContainer
              show={showSort}
              onClose={() => setShowSort(false)}
              titleCls='text-red-500'
              title={<div className='mx-auto text-center font-medium capitalize text-black'>{compStr.sort_by}</div>}
              innerProps={{ className: 'h-auto w-full overflow-clip' }}
              bodyCls='!p-0 flex flex-col items-stretch'
            >
              {OPTIONS.map((item, index) => (
                <button
                  key={index}
                  onClick={() => {
                    onSort(category, item)
                    setShowSort(false)
                  }}
                  disabled={item['value'] == (sortBy?.value || 'featured')}
                  className='border-t px-4 py-2 text-left first:border-t-2 enabled:bg-white/100 enabled:hover:bg-amber-v1 disabled:bg-amber-v1 disabled:font-medium'
                >
                  {item['text']}
                </button>
              ))}
            </ModalContainer>

            {/* Preserve filters value at the time of opening the modal */}
            <IconButton className='ml-4 flex h-7 w-7 p-1' onClick={() => setShowFilter({ from: from, to: to, category: category })}>
              {svgs.filter}
            </IconButton>
            <ModalContainer
              show={showFilter ? true : false} // open when the preservation of filters value exists
              onClose={() => setShowFilter(null)} // close when the preservation of filters value is cleared
              titleCls='text-red-500'
              title={<div className='mx-auto text-center font-medium capitalize text-black'>{compStr.filters}</div>}
              bodyCls='border-t-2'
            >
              <div className='mr-auto mt-2 flex w-full flex-col items-center gap-y-2' data-testid='slider-filter-ticketPrice'>
                {compStr.ticket_prices}
                <DuoSliders
                  fromCtrl={fromCtrl}
                  toCtrl={toCtrl}
                  min={defaultFrom}
                  max={defaultTo}
                  outerCls='gap-x-1'
                  // @ts-ignore
                  innerProps={{ 'data-testid': 'slider-filter-ticketPrice' }}
                />
              </div>
              <Dropdown
                outerCls='mt-6'
                input={{
                  'value': category.text || '',
                  'placeholder': compStr.category,
                  'className': 'cursor-pointer w-full',
                  // @ts-ignore
                  'data-testid': 'div-filter-category',
                }}
                value={category.value}
                options={cateList}
                onSelect={setCategory}
                border
              />
              <div className='mt-4 text-right'>
                <span onClick={clearFilters} className='clickable-text'>
                  {compStr.clear_filters}
                </span>
              </div>
              <div className='mt-6 flex items-center gap-x-4'>
                <button
                  onClick={() => {
                    // Don't apply changed value to filters, reset to value preserved at the time of opening the modal
                    setFrom(showFilter?.from)
                    setTo(showFilter?.to)
                    setCategory(showFilter?.category)
                    setShowFilter(null) // clear preservation of filters value, i.e, close the modal
                  }}
                  type='button'
                  className='btn-hover-amber btn-rd-outline h-10 w-full'
                >
                  {actStr.cancel}
                </button>
                <button
                  onClick={() => {
                    // Apply changed value to filters
                    applyFilters(category, from, to, sortBy, search)
                    setShowFilter(null) // clear preservation of filters value, i.e, close the modal
                  }}
                  type='button'
                  className='btn-hover-amber btn-rd-amber h-10 w-full'
                >
                  {compStr.apply_filters}
                </button>
              </div>
            </ModalContainer>
          </>
        )}
      </div>
    </div>
  )
}

export default AllCompetitions
