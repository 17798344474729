import { API_URL, LATEST_WINNERS_PER_PAGE } from '@/utils/Consts'
import { Winner } from '@models/Index'
import { appUtils } from 'lib/AppUtils'
import { FC, useEffect, useState } from 'react'
import Slider from 'react-slick'
import useSWRImmutable from 'swr/immutable'
import { WinnerItem } from './WinnerItem'

export const WinnerCarousel: FC<{ extraCls?: string }> = ({ extraCls }) => {
  const [page, setPage] = useState(1)
  const [winners, setWinners] = useState<Winner[]>([])
  const { data, isValidating, isLoading } = useSWRImmutable(`${API_URL.winners}?perPage=${LATEST_WINNERS_PER_PAGE}&page=${page}`, appUtils.fetcher)
  useEffect(() => {
    if (!isValidating && !isLoading && data?.data?.length > 0) {
      const arr = [...winners]
      data?.data?.forEach(x => arr.push(x))
      setWinners(arr)
      if (data.current_page < 2) {
        setPage(data.current_page + 1)
      }
    }
  }, [data, isValidating, isLoading])
  return (
    <div id='winner-carousel' className={`contents xl:container md:block md:px-4 md:pb-6 ${extraCls || ''}`}>
      <Slider
        arrows={false}
        infinite={true}
        autoplay={true}
        autoplaySpeed={3000}
        slidesToShow={LATEST_WINNERS_PER_PAGE}
        slidesToScroll={1}
        speed={3000}
        cssEase='linear'
        className='h-24 w-full overflow-hidden border-4 border-amber-v1 md:rounded-full'
      >
        {winners?.length > 0
          ? winners?.map((item: Winner, index: number) => (
              <WinnerItem
                key={'winner-carousel-' + page + '-' + index}
                winner={item}
                className='h-24 max-h-24 p-0'
                imgCls='h-24 max-h-24 w-full object-cover'
                disabled
              />
            ))
          : null}
      </Slider>
    </div>
  )
}

export default WinnerCarousel
