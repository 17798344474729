import { noImage } from '@/utils/Images'
import { DetailedHTMLProps, FC, ImgHTMLAttributes, useEffect, useState } from 'react'

export function ImgFallback({ src, fallbackSrc = null, ...rest }) {
  const [imgSrc, setImgSrc] = useState(src)
  useEffect(() => setImgSrc(src), [src])
  return <img {...rest} src={imgSrc} onError={() => setImgSrc(fallbackSrc ?? noImage.src)} />
}

/**
 * failed to load img --> will show alt as span
 * @param {string} alt required
 * @returns
 */
export const ImgAltSpan: FC<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>> = ({ src, alt, className, ...rest }) => {
  const [imgLoaded, setImgLoaded] = useState<-1 | 0 | 1>(0) // error | loading | loaded
  return (
    <>
      <img
        {...rest}
        src={src}
        onError={() => setImgLoaded(-1)}
        onLoad={() => setImgLoaded(prev => (prev == -1 ? -1 : 1))}
        className={`transition-all duration-300 ${imgLoaded != 1 ? 'hidden' : className || ''}`}
      />
      <span className={`transition-all duration-300 ${imgLoaded == 1 ? 'hidden' : ''}`}>&nbsp;{alt}&nbsp;</span>
    </>
  )
}

export const ImgSkeleton: FC<{ className?: string }> = ({ className = '' }) => {
  return (
    <svg className={className} viewBox='0 0 20 18' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z' />
    </svg>
  )
}
