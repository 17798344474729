import BannerSlider from '@/components/BannerSlider'
import { AllCompetitions } from '@/components/Competition'
import HowToPlay from '@/components/HowToPlay'
import LatestWinners from '@/components/LatestWinners'
import WebInfo from '@/components/WebInfo'
import WinnerCarousel from '@/components/WinnerCarousel'
import { API_URL } from '@/utils/Consts'
import { appUtils } from 'lib/AppUtils'
import { FC, Fragment, PropsWithChildren } from 'react'
import useSWRImmutable from 'swr/immutable'

export const CompetitionsPageContent: FC<PropsWithChildren<{ dataFetched?: any }>> = ({ dataFetched }) => {
  const { data, isValidating, isLoading } = useSWRImmutable(dataFetched ? null : `${API_URL.competitionList}?store_id=1&source=c`, appUtils.fetcher)
  return (
    <Fragment>
      <BannerSlider />
      {dataFetched ? <AllCompetitions data={dataFetched} /> : <AllCompetitions data={isValidating || isLoading ? null : data || []} />}
      <WebInfo />
      <WinnerCarousel />
      <HowToPlay />
      <LatestWinners />
    </Fragment>
  )
}
export default CompetitionsPageContent
