import { useEffect, useState } from 'react'

export function useCount(start: boolean, end: number, durationMS: number) {
  const [count, setCount] = useState(0)
  useEffect(() => {
    if (!start) return
    const duration = durationMS / end
    const interval = setInterval(
      () => {
        setCount(prev => {
          const increment = duration < 10 ? 10 / duration : 1
          const next = prev + increment
          if (next <= end) return next
          else if (end - prev > 0) return end
          else {
            clearInterval(interval)
            return prev
          }
        })
      },
      duration < 10 ? 10 : duration
    )

    return () => clearInterval(interval)
  }, [start])
  return count
}
