import { useLanguage, useLayout } from '@/hooks/Contexts'
import { API_URL, LATEST_WINNERS_PER_PAGE } from '@/utils/Consts'
import { Winner } from '@models/Index'
import { appUtils } from 'lib/AppUtils'
import Link from 'next/link'
import { FC, useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import useSWRImmutable from 'swr/immutable'
import SliderArrows from './buttons/SliderArrows'
import { WinnerItem } from './WinnerItem'

export const LatestWinners: FC<{ extraCls?: string }> = ({ extraCls }) => {
  const { categories: cateStr } = useLanguage()
  const { isDesktop, breakpoint } = useLayout()
  const [winners, setWinners] = useState<Winner[]>([])

  const { data, isValidating, isLoading } = useSWRImmutable(`${API_URL.winners}?perPage=${LATEST_WINNERS_PER_PAGE}&page=1`, appUtils.fetcher)

  useEffect(() => {
    if (!isValidating && !isLoading) {
      setWinners(data?.data?.slice?.(0, 5) || [])
    }
  }, [data, isValidating, isLoading])

  return (
    <div id='latest-winners' className={`${isDesktop ? 'contents' : 'fluid-gray'} ${extraCls || ''}`}>
      <div className='container-gray mb-4 flex w-full items-center justify-between text-lg md:pt-6'>
        <div className='md:text-2xl'>
          {cateStr.latest_winners[0]}
          <span className='font-semibold text-cyan-v2'>{cateStr.latest_winners[1]}</span>
        </div>

        <Link href='/winners' passHref>
          <a className='clickable-text font-semibold'>{cateStr.view_all}</a>
        </Link>
      </div>

      {!breakpoint ? null : breakpoint.includes('xl') ? (
        <div className='container mt-4 grid w-full grid-cols-4 grid-rows-2 place-items-center gap-x-4 gap-y-4 px-4 pb-6'>
          {winners.map((winner: Winner, index: number) => (
            <WinnerItem
              key={'latest-winner-' + index}
              winner={winner}
              className='col-span-1 row-span-1 h-full w-full rounded-[10px] bg-white/100 first:xl:col-span-2 first:xl:row-span-2'
              imgCls={`object-cover h-full ${index == 0 ? '' : 'max-h-72'}`}
              bodyCls='absolute inset-4 flex flex-col items-center justify-center text-center opacity-0 transition-opacity duration-300 group-hover:opacity-100'
              titleCls={`pb-2 border-b-2 ${index % 2 == 0 ? 'text-black border-black' : 'text-white/100 border-white/100'}`}
              priceCls={`mt-2 ${index % 2 == 0 ? 'text-black' : 'text-white/100'}`}
            >
              <div
                className={`absolute inset-0 h-full w-full opacity-0  transition-opacity duration-300 group-hover:opacity-70 ${
                  index % 2 == 0 ? 'bg-amber-v1' : 'bg-cyan-v2'
                }`}
              ></div>
            </WinnerItem>
          ))}
        </div>
      ) : (
        <WinnerSlider items={winners} />
      )}
    </div>
  )
}

const WinnerSlider: FC<{ items: Array<Record<string, any>> }> = ({ items }) => {
  const { isDesktop } = useLayout()
  const sliderRef = useRef<Slider>(null)
  return (
    <>
      <Slider
        ref={sliderRef}
        slidesToShow={isDesktop ? 2 : 1.5}
        slidesToScroll={1}
        arrows={false}
        infinite={true}
        focusOnSelect={true}
        // centerMode
        fade={false}
      >
        {items.map((item: Winner, index: number) => (
          <WinnerItem
            key={'latest-winner-' + index}
            winner={item}
            className='h-full rounded-lg bg-white/100 shadow-lg shadow-stone-200'
            imgCls='w-full object-cover h-40'
            titleCls='text-center text-sm px-2 py-4 flex items-center justify-center h-[calc(100%-208px)]'
            priceCls='text-center text-xs border-t py-4 mx-2 text-neutral-600 border-neutral-300 h-12'
          />
        ))}
      </Slider>
      <SliderArrows
        prevClick={() => sliderRef.current?.slickPrev?.()}
        nextClick={() => sliderRef.current?.slickNext?.()}
        className='justify-center md:pb-6'
        btnCls='shadow-lg shadow-stone-200 last:ml-8'
      />
    </>
  )
}

export default LatestWinners
