import { useLanguage } from '@/hooks/Contexts'
import { useScrollable } from '@/hooks/Index'
import { svgs } from '@/utils/Images'
import { Action0 } from '@/utils/Types'
import { ButtonHTMLAttributes, FC, HTMLAttributes, PropsWithChildren, ReactNode, useState } from 'react'
import { ModalVideoDrawPlayer } from './Layouts/Index'
interface ModalProps {
  show: boolean
  onClose?: Action0
  outerCls?: string
  innerProps?: HTMLAttributes<HTMLDivElement>
  title?: ReactNode
  titleCls?: string
  bodyCls?: string
}
export const ModalContainer: FC<PropsWithChildren<ModalProps>> = ({ show, onClose, outerCls, innerProps, title, titleCls, children, bodyCls }) => {
  useScrollable(show)
  return (
    <div
      className={`fixed top-0 left-0 z-50 flex h-screen w-screen items-center transition-all duration-500 ${
        show ? 'translate-y-[10px] opacity-100' : 'pointer-events-none -translate-y-[10px] opacity-0'
      } ${outerCls || ''}`}
      aria-labelledby='modal'
      role='dialog'
      aria-modal='true'
    >
      <div className='fixed inset-0 bg-stone-900/70' aria-hidden='true' />
      <div {...innerProps} className={`relative mx-4 w-full rounded-lg bg-white/100 shadow md:mx-auto ${innerProps?.className || ''}`}>
        <div className={`flex w-full items-center justify-end px-4 py-2 md:py-4 md:px-6 ${titleCls || ''}`}>
          {title}
          <span onClick={onClose} className='h-4 w-4 cursor-pointer hover:text-amber-500'>
            {svgs.cross}
          </span>
        </div>
        <div className={`px-4 pb-4 md:px-6 md:pb-6 ${bodyCls || ''}`}>{children}</div>
      </div>
    </div>
  )
}

export type ModalWithImageProps = {
  type?: 'success' | 'image' | 'warning'
  imgUrl?: string
  content?: string
  title?: string
  alt?: string
  onConfirm?: Action0
}
export const ModalWithImage: FC<ModalProps & ModalWithImageProps> = ({ show, onClose, onConfirm, type, imgUrl, title, alt, content, children }) => {
  const { competitions: compStr, cart: cartStr } = useLanguage()

  return (
    <ModalContainer
      show={show}
      onClose={onClose}
      title={type == 'success' ? <div className='mx-auto text-center font-medium capitalize text-black'>{cartStr.congratulations}</div> : null}
      innerProps={{ className: type == 'image' ? 'md:w-1/2 xl:max-w-[480px]' : 'md:w-1/2 lg:w-96' }}
    >
      {/* Image */}
      <div className={`flex w-full min-w-fit justify-center ${type === 'success' ? 'rounded-xl bg-cyan-v2' : 'h-auto'}`}>
        {type == 'image' ? (
          imgUrl && <img src={imgUrl} alt={alt ?? imgUrl} className='h-auto max-h-80 w-full rounded object-fill' />
        ) : type == 'success' ? (
          <svg className='my-8 w-1/4 fill-white/100' viewBox='0 0 330 330'>
            {svgs.check}
          </svg>
        ) : type == 'warning' ? (
          <svg className='my-8 w-1/4 fill-white/100' viewBox='0 0 330 330' stroke='currentColor' strokeWidth={2}>
            {svgs.warning}
          </svg>
        ) : null}
      </div>

      {/* Main content */}
      <div className='mt-4 text-center'>
        {type == 'image' && title && <p className='mb-2 text-base font-semibold md:text-lg'>{title}</p>}
        <p className='text-sm md:text-base' data-testid={type == 'success' ? 'txt-competition-addedbasket' : null}>
          {content}
        </p>
      </div>
      <div className='mt-4 grid w-full min-w-max grid-cols-2 gap-4'>
        <button className='btn-rd-outline btn-hover-amber h-10' onClick={onClose} data-testid={type == 'success' ? '' : null}>
          {type == 'image' ? compStr.maybe_later : type == 'warning' ? cartStr.let_me_think : cartStr.keep_shopping}
        </button>
        <button className='btn-rd-amber btn-hover-amber h-10' onClick={onConfirm} data-testid={type == 'success' ? 'btn-addCart-chkOut' : null}>
          {type == 'image' ? compStr.details : type == 'warning' ? cartStr.confirm : cartStr.checkout}
        </button>
      </div>
      {children}
    </ModalContainer>
  )
}

interface ConfirmModalProps extends ModalProps {
  content?: string
  title?: ReactNode
  confirmProps?: ButtonHTMLAttributes<HTMLButtonElement>
  showCloseBtn?: boolean
  tip?: boolean
}
export const ConfirmModal: FC<ConfirmModalProps> = ({ show, onClose, confirmProps, title, content, showCloseBtn, tip }) => {
  const { cart: cartStr } = useLanguage()
  return (
    <ModalContainer show={show} onClose={onClose} innerProps={{ className: 'lg:w-3/5 md:w-2/3 2xl:w-2/5' }}>
      <div className='mb-4 flex w-full flex-col'>
        {tip ? (
          <p className='text-center text-lg font-semibold lg:text-xl'>{title}</p>
        ) : (
          <div className='flex'>
            <svg className='mr-2 mt-1 h-5 w-5 text-warning md:mr-4' viewBox='0 0 24 24' width='100%' height='100%' fill='currentColor'>
              <path d='M23.32 17.191L15.438 2.184C14.728.833 13.416 0 11.996 0c-1.42 0-2.733.833-3.443 2.184L.533 17.448a4.744 4.744 0 000 4.368C1.243 23.167 2.555 24 3.975 24h16.05C22.22 24 24 22.044 24 19.632c0-.904-.251-1.746-.68-2.44zm-9.622 1.46c0 1.033-.724 1.823-1.698 1.823s-1.698-.79-1.698-1.822v-.043c0-1.028.724-1.822 1.698-1.822s1.698.79 1.698 1.822v.043zm.039-12.285l-.84 8.06c-.057.581-.408.943-.897.943-.49 0-.84-.367-.896-.942l-.84-8.065c-.057-.624.25-1.095.779-1.095h1.91c.528.005.84.476.784 1.1z'></path>
            </svg>
            <p className='mb-2 text-lg font-semibold lg:text-xl'>{title}</p>
          </div>
        )}
        <p className='whitespace-pre-wrap'>{content}</p>
      </div>
      {tip ? (
        <div className='mt-4 flex w-full justify-center'>
          <button className='btn-primary w-full max-w-[240px] !rounded py-3 font-semibold capitalize' onClick={confirmProps?.onClick} type='button'>
            {tip}
          </button>
        </div>
      ) : (
        <div className='mt-8 flex w-full flex-row-reverse gap-x-4'>
          {confirmProps && (
            <button {...confirmProps} className='btn-rd-outline btn-hover-amber h-10 px-4' type='button'>
              {cartStr.confirm}
            </button>
          )}
          {onClose && showCloseBtn && (
            <button className='btn-rd-amber btn-hover-amber h-10 px-4' onClick={onClose} type='button'>
              {cartStr.close}
            </button>
          )}
        </div>
      )}
    </ModalContainer>
  )
}

interface IframeModalProps extends ModalProps {
  url?: string
  iframe?: string
}
export const IframeModal: FC<IframeModalProps> = ({ url, iframe, show, onClose }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isPlayerIFrame, setIsPlayerIFrame] = useState(false)
  const close = () => {
    if (onClose) {
      onClose()
      setIsPlaying(false)
    }
  }
  return (
    <ModalContainer show={show} onClose={close} innerProps={{ className: 'h-auto w-full md:w-2/3 lg:w-1/2' }}>
      {(url || iframe) && (show || !isPlayerIFrame) && (
        <ModalVideoDrawPlayer url={url} iframe={iframe} isPlaying={isPlaying} toggleIsPlayerIFrame={setIsPlayerIFrame} toggleIsPlaying={setIsPlaying} />
      )}
    </ModalContainer>
  )
}
